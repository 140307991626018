import { 
  imgInputTextsHelpers,
  errorMessageHelpers,
  hasError 
} from '@/_helpers/funciones';
//Data:
function data() {
  return {}
}

//Meodos:
function errorMessage(field){
  return errorMessageHelpers(this, field);
}
function touchRequiredValues() {
  this.$v.linea.ShippingLineName.$touch();
  this.$v.linea.ShippingLineAbbrev.$touch();
  this.$v.linea.Address.$touch();
  if(this.$v.linea.PrimaryPhone.$model)
    this.$v.linea.PrimaryPhone.$touch();
  if(this.$v.linea.SecondaryPhone.$model)
    this.$v.linea.SecondaryPhone.$touch();
  if(this.$v.linea.PrimaryEmail.$model)
    this.$v.linea.PrimaryEmail.$touch();
  if(this.$v.linea.SecondaryEmail.$model)
    this.$v.linea.SecondaryEmail.$touch();
}
function imgInputTexts(){
  return imgInputTextsHelpers(this);
}

//Computeds
function isSubmitValid(){
  return this.$v.$invalid || this.isSubmit;
}

export default {
  data,
  methods: {
    hasError,
    errorMessage,
    touchRequiredValues
  },
  computed: {
    isSubmitValid,
    imgInputTexts,
  }
}