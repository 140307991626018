import {
    required,
    minLength,
    maxLength,
    email,
    alpha,
    helpers
  } from 'vuelidate/lib/validators';
  
  import { textareaSpecials, phoneTest } from '../funciones';
  import { inputColor } from '../validacionEspeciales';
  
  const mobileNotReq = (value) => !helpers.req(value) || phoneTest(value);
  const emailToLower = (value) => email(value/*.toLowerCase()*/);

  const isValidFile = (value) => {
    if(!value)
      return false;
    
    if(value.type)
      return value.type.startsWith('image');
    else
        return (value != '');
  };

  const alphaSpecials = (value) => /^[a-zA-Z 0-9 \u00f1\u00d1,.\-\/\$\&\(\)]+$/g.test(value);

  export default () => {
    return {
      linea: {
        ShippingLineName: { required, alphaSpecials, maxLength: maxLength(50) },
        ShippingLineAbbrev: { required, alphaSpecials, maxLength: maxLength(8) },
        Address: { textareaSpecials, maxLength: maxLength(150) },
        PrimaryPhone: { mobileNotReq,  minLength: minLength(11), maxLength: maxLength(15) },
        SecondaryPhone: { mobileNotReq, minLength: minLength(11), maxLength: maxLength(15) },
        PrimaryEmail: { email: emailToLower, maxLength: maxLength(100) },
        SecondaryEmail: { email: emailToLower, maxLength: maxLength(100) },
        LineRoute: { isValidFile  },
        Color: { required, inputColor }
      }
    }
  }